<template>
  <div>
    <load-profile></load-profile>
    <b-row class="mb-1" v-if="data.id">
      <b-col class="text-right">
        <b-button-group size="sm">
          <b-button
            variant="secondary"
            :disabled="!data.prevId || loading"
            @click="goTo(data.prevId)"
          >
            <feather-icon icon="ChevronLeftIcon" />
          </b-button>
          <b-button
            variant="secondary"
            :disabled="!data.nextId || loading"
            @click="goTo(data.nextId)"
          >
            <feather-icon icon="ChevronRightIcon" />
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>
    <section class="invoice-preview-wrapper">
      <b-row v-if="data.orderCode" class="invoice-preview">
        <b-col cols="12" md="8" xl="9">
          <b-card no-body class="invoice-preview-card">
            <b-card-body class="invoice-padding pb-0">
              <div
                class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
              >
                <div v-if="data.agent">
                  <div class="logo-wrapper">
                    <h3 class="text-primary invoice-logo ml-0">
                      {{ data.agent.agentCode }}
                    </h3>
                  </div>
                  <p class="card-text mb-25">
                    {{
                      $i18n.locale == "en"
                        ? data.agent.addressEn
                        : data.agent.addressKm
                    }}
                  </p>
                  <p class="card-text mb-0">
                    {{ data.agent.contactNumber }}
                  </p>
                </div>

                <div class="mt-md-0 mt-2">
                  <h4 class="invoice-title">
                    <span class="invoice-number">#{{ data.orderCode }}</span>
                  </h4>
                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">
                      {{ $t("field.dateIssued") }}:
                    </p>
                    <p class="invoice-date">
                      {{ data.createdAt | formatDate }}
                    </p>
                  </div>
                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">{{ $t("field.state") }}:</p>
                    <b-badge :class="data.state" pill>
                      {{ $t(`orderState.${data.state}`) }}
                    </b-badge>
                  </div>
                </div>
              </div>
            </b-card-body>

            <hr class="invoice-spacing" />

            <b-card-body v-if="data.outlet" class="invoice-padding pt-0">
              <b-row class="invoice-spacing">
                <b-col cols="12" xl="6" class="p-0">
                  <h6 class="mb-2">{{ $t("field.invoiceTo") }}:</h6>
                  <h6 class="mb-25">
                    {{ data.outlet.ownerName }}
                  </h6>
                  <p class="card-text mb-25">
                    {{ data.outlet.outletCode }}
                  </p>
                  <p class="card-text mb-25">
                    {{ trans(data.outlet, "name", $i18n.locale) }}
                  </p>
                  <p class="card-text mb-25">
                    {{ trans(data.outlet, "address", $i18n.locale) }}
                  </p>
                  <p class="card-text mb-25">
                    {{ data.outlet.contactNumber }}
                  </p>
                </b-col>

                <b-col
                  xl="6"
                  cols="12"
                  class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
                >
                  <div>
                    <h6 class="mb-2">{{ $t("field.payment") }}:</h6>
                    <table>
                      <tbody>
                        <tr>
                          <td class="pr-1">{{ $t("field.total") }} :</td>
                          <td>
                            <span
                              class="font-weight-bold"
                              v-if="data.type == 1"
                            >
                              {{ data.total | currency }}
                            </span>
                            <span v-else>-</span>
                          </td>
                        </tr>
                        <tr v-if="data.paymentMethod">
                          <td class="pr-1">{{ $t("field.paymentMethod") }}:</td>
                          <td>
                            <span v-if="data.type == 1">
                              {{
                                trans(data.paymentMethod, "name", $i18n.locale)
                              }}
                            </span>
                            <span v-else>-</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <b-table-lite responsive :items="data.lines" :fields="fields">
              <template #head()="scope">
                <div class="text-nowrap">{{ $t(scope.label) }}</div>
              </template>

              <template #cell(increasement)="data">
                {{ data.index + 1 }}
              </template>
              <template #cell(description)="data">
                <span>
                  {{ trans(data.item, "productName", $i18n.locale) }}
                  <br />
                  <small class="text-primary">
                    {{ data.item.productCode }}</small
                  >
                  <div
                    v-for="(promotion, i) in data.item.promotions"
                    :key="`promotion-name-${i}`"
                  >
                    <small> {{ promotion }} </small>
                  </div>
                </span>
              </template>
              <template #cell(quantity)="data">
                {{ data.item.quantity }}
                <span v-if="data.item.product">
                  {{ data.item.product.saleUom }}
                </span>
              </template>
              <template #cell(unitPrice)="item">
                <span v-if="data.type == 1">
                  {{ item.item.unitPrice | currency }}
                </span>
                <span v-else>-</span>
              </template>
              <template #cell(subTotal)="item">
                <span v-if="data.type == 1">
                  {{ item.item.subTotal | currency }}
                </span>
                <span v-else>-</span>
              </template>
              <template #cell(amountOff)="item">
                <span
                  class="text-negative"
                  v-if="item.item.amountOff && data.type == 1"
                >
                  {{ item.item.amountOff | currency }}
                </span>
                <span v-else>-</span>
              </template>
              <template #cell(total)="item">
                <span
                  :class="{
                    'text-primary': item.item.total > 0,
                    'text-negative': item.item.total < 0,
                  }"
                  v-if="data.type == 1"
                >
                  {{ item.item.total | currency }}
                </span>
                <span v-else>-</span>
              </template>
            </b-table-lite>

            <b-card-body class="invoice-padding pb-0">
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3"
                  order="2"
                  order-md="1"
                >
                  <b-card-text class="mb-0" v-if="data.salesperson">
                    <span class="font-weight-bold"
                      >{{ $t("field.salesperson") }}:</span
                    >
                    <span class="ml-75">{{ data.salesperson.label }}</span>
                  </b-card-text>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >
                  <div class="invoice-total-wrapper">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        {{ $t("field.subTotal") }}:
                      </p>
                      <p class="invoice-total-amount" v-if="data.type == 1">
                        {{ data.subTotal | currency }}
                      </p>
                      <p class="invoice-total-amount" v-else>-</p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        {{ $t("field.discount") }}:
                      </p>
                      <p
                        class="invoice-total-amount text-negative"
                        v-if="data.amountOff && data.type == 1"
                      >
                        {{ data.amountOff | currency }}
                      </p>
                      <p class="invoice-total-amount" v-else>-</p>
                    </div>
                    <hr class="my-50" />
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        {{ $t("field.total") }}:
                      </p>
                      <p
                        class="invoice-total-amount text-primary"
                        v-if="data.type == 1"
                      >
                        {{ data.total | currency }}
                      </p>
                      <p class="invoice-total-amount text-primary" v-else>-</p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <hr class="invoice-spacing" />

            <b-card-body class="invoice-padding pt-0" v-if="data.note">
              <span class="font-weight-bold">{{ $t("field.note") }}: </span>
              <span> {{ data.note }} </span>
            </b-card-body>
            <b-card-body
              class="invoice-padding pt-0"
              v-if="data.expectDeliveryDate"
            >
              <span class="font-weight-bold"
                >{{ $t("field.expectDeliveryDate") }}:
              </span>
              <span> {{ data.expectDeliveryDate | formatDate("LL") }} </span>
            </b-card-body>
            <b-card-body class="invoice-padding pt-0" v-if="data.contactNumber">
              <span class="font-weight-bold"
                >{{ $t("field.contactNumber") }}:
              </span>
              <span> {{ data.contactNumber }} </span>
            </b-card-body>
          </b-card>
        </b-col>

        <b-col cols="12" md="4" xl="3" class="invoice-actions">
          <b-card>
            <n-button-loading
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :loading="loading"
              variant="primary"
              class="mb-75"
              block
              v-if="canAccept()"
              @submit="acceptOrder"
            >
              {{ $t("button.acceptOrder") }}
            </n-button-loading>
            <n-button-loading
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :loading="loading"
              variant="primary"
              class="mb-75"
              block
              v-if="canShipping()"
              @submit="shippingOrder()"
            >
              {{ $t("button.prepareShipping") }}
            </n-button-loading>
            <n-button-loading
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :loading="loading"
              variant="success"
              class="mb-75"
              block
              v-if="canComplete()"
              @submit="completeOrder()"
            >
              {{ $t("button.completeOrder") }}
            </n-button-loading>
            <n-button-loading
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :loading="loading"
              variant="outline-danger"
              class="mb-75"
              block
              v-if="canCancel()"
              @submit="cancelOrder()"
            >
              {{ $t("button.cancelOrder") }}
            </n-button-loading>

            <hr class="my-1" v-if="canCancel()" />

            <b-button variant="primary" block @click="showRemark">
              {{ $t("button.log") }}
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </section>

    <cancel-order-modal ref="cancelOrderModal" @ok="confirmCancelOrder" />
    <remark-modal ref="remarkModal" />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardText,
  BTableLite,
  BSpinner,
  BAvatar,
  BBadge,
  BButton,
  BButtonGroup,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import Ripple from "vue-ripple-directive";
import NButtonLoading from "@/components/NButtonLoading";
import { avatarText } from "@core/utils/filter";
import orderState from "@/data/orderState";
import CancelOrderModal from "./CancelOrderModal";
import RemarkModal from "./remark/RemarkModal.vue";

const OrderRepository = Repository.get("order");

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BTableLite,
    BSpinner,
    BAvatar,
    BBadge,
    BButton,
    BButtonGroup,

    CancelOrderModal,
    NButtonLoading,
    RemarkModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {},
      fields: [
        {
          key: "increasement",
          label: "#",
          thClass: "w-50px text-center",
          tdClass: "text-center",
        },
        {
          key: "description",
          label: "field.description",
        },
        {
          key: "quantity",
          label: "field.quantity",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "unitPrice",
          label: "field.unitPrice",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "subTotal",
          label: "field.subTotal",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "amountOff",
          label: "field.discount",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "total",
          label: "field.total",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      loading: false,
      query: {
        page: 1,
        order: "id",
        sort: "DESC",
      },
      logs: [],
      total: 0,
      orderState,
      avatarText,
    };
  },
  mounted() {
    this.show();
  },
  created() {
    this.$bus.$on("notification-click", () => {
      this.show();
      this.logs = [];
      this.resetOrderLog();
    });
  },
  beforeDestroy() {
    this.$bus.$off("notification-click");
  },
  methods: {
    showRemark() {
      this.$refs.remarkModal.show(this.data);
    },
    goTo(id) {
      if (!id) {
        return;
      }
      this.$router.replace({
        name: `view-${this.route}`,
        params: {
          id: id,
        },
      });
      this.show();
    },
    show() {
      this.data = {};
      OrderRepository.show(this.$route.params.id).then((response) => {
        const data = response?.data?.data;
        if (data) {
          this.data = data;
        }
      });
    },
    acceptOrder() {
      this.$bvModal
        .msgBoxConfirm(this.$t("alert.accpetOrder"), {
          title: this.$t("alert.areYouSure"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("button.acceptOrder"),
          cancelTitle: this.$t("button.cancel"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true;
            OrderRepository.accept(this.$route.params.id)
              .then((response) => {
                this.$router.push({
                  name: "create-sales-order",
                });
                // const data = response?.data?.data;
                // if (data) {
                //   this.data = { ...data };
                //   this.resetOrderLog();
                // }
              })
              .catch(() => (this.loading = false))
              .then(() => (this.loading = false));
          }
        });
    },
    shippingOrder() {
      this.$bvModal
        .msgBoxConfirm(this.$t("alert.prepareShipping"), {
          title: this.$t("alert.areYouSure"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("button.prepareShipping"),
          cancelTitle: this.$t("button.cancel"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true;
            OrderRepository.shipping(this.$route.params.id)
              .then((response) => {
                const data = response?.data?.data;
                if (data) {
                  this.data = { ...data };
                  this.resetOrderLog();
                }
              })
              .catch(() => (this.loading = false))
              .then(() => (this.loading = false));
          }
        });
    },
    completeOrder() {
      this.$bvModal
        .msgBoxConfirm(this.$t("alert.completeOrder"), {
          title: this.$t("alert.areYouSure"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          okTitle: this.$t("button.completeOrder"),
          cancelTitle: this.$t("button.cancel"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true;
            OrderRepository.complete(this.$route.params.id)
              .then((response) => {
                const data = response?.data?.data;
                if (data) {
                  this.data = { ...data };
                  this.resetOrderLog();
                }
              })
              .catch(() => (this.loading = false))
              .then(() => (this.loading = false));
          }
        });
    },
    cancelOrder() {
      this.$refs.cancelOrderModal.show();
    },
    confirmCancelOrder(note) {
      this.loading = true;
      OrderRepository.cancel(this.$route.params.id, note)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.data = { ...data };
            this.resetOrderLog();
          }
        })
        .catch(() => (this.loading = false))
        .then(() => (this.loading = false));
    },
    resetOrderLog() {
      this.query.page = 1;
      this.infiniteLoadingKey++;
    },
    canAccept() {
      return this.data.state == orderState.pending;
    },
    canShipping() {
      return this.data.state == orderState.preparing;
    },
    canComplete() {
      return this.data.state == orderState.shipping;
    },
    canCancel() {
      return (
        this.data.state != orderState.cancel &&
        this.data.state != orderState.reject &&
        this.data.state != orderState.complete
      );
    },
    back() {
      this.$router.push({
        name: `list-${this.route}`,
      });
    },
  },
  setup() {
    const resource = "agent-order";
    const route = "order";

    return { resource, route };
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
