<template>
  <b-modal
    id="modal-cancel-order"
    ok-variant="danger"
    cancel-variant="outline-secondary"
    :ok-title="$t('button.cancelOrder')"
    :cancel-title="$t('button.close')"
    centered
    :title="$t('alert.areYouSure')"
    button-size="sm"
    @ok="handleOk"
  >
    <validation-observer ref="cancelForm">
      <validation-provider
        #default="{ errors }"
        vid="remark"
        name="field.remark"
        rules="required|max:500"
      >
        <b-form-group :label="$t('general.whyCancelOrder')">
          <b-form-textarea
            v-model="data.note"
            id="remark"
            name="remark"
            :placeholder="$t('field.remark')"
            :rows="2"
            :max-rows="6"
            :class="errors.length > 0 ? 'is-invalid' : null"
          ></b-form-textarea>

          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>
    </validation-observer>
  </b-modal>
</template>

<script>
import { BModal, BFormGroup, BFormTextarea } from "bootstrap-vue";

export default {
  components: {
    BModal,
    BFormGroup,
    BFormTextarea,
  },
  watch: {
    "data.type": function (value) {
      this.updateTitle(value);
      return;
    },
  },
  data() {
    return {
      loading: false,
      data: {
        note: "",
      },
    };
  },
  methods: {
    show() {
      this.data.note = "";
      this.$bvModal.show("modal-cancel-order");
    },
    hide() {
      this.$bvModal.hide("modal-cancel-order");
    },
    handleOk(bvModal) {
      bvModal.preventDefault();
      if (this.loading) {
        return;
      }

      this.$refs.cancelForm.validate().then((success) => {
        if (success) {
          this.$emit("ok", this.data.note);
          this.hide();
        }
      });
    },
  },
};
</script>